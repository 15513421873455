import './App.css';

// Links Randomization \\
const yeslinks = [
  'https://babyurthe.one',
  'https://c.tenor.com/ynST0DWtFqgAAAAM/pointing-that-is-correct.gif',
  'https://i.giphy.com/media/l1J9wXoC8W4JFmREY/200.gif',
  'https://c.tenor.com/rO6Fh2KNm3sAAAAM/baby-yes.gif',
  'https://c.tenor.com/8moq9uPTnAUAAAAC/kevin-hart-hell.gif'
]
// Links if you pick the correct cookie, randomized
const yesrn = require('random-number');
const yesopt = {
  min: 0,
  max: 4,
  integer: true
}

/*
const yeslink1 = yeslinks[yesrn(yesopt)];
const yeslink2 = yeslinks[yesrn(yesopt)];
const yeslink3 = yeslinks[yesrn(yesopt)];
*/

// Links if you pick the wrong cookie, randomized
const wronglinks = [
  'https://y.yarn.co/75be7dc0-ba7b-4dd1-a6b2-abb3a30af385_text.gif',
  'https://c.tenor.com/-rnEYzty4DoAAAAM/theoffice-no.gif',
  'https://i.giphy.com/media/3oz8xLd9DJq2l2VFtu/giphy.webp',
  'https://i.giphy.com/media/3taYXLxSBOugHHjocB/giphy.webp',
  'https://i.giphy.com/media/A6t3PEz3EWiRwL017s/200.gif'
]
const norn = require('random-number');
const noopt = {
  min: 0,
  max: 4,
  integer: true
}

/*
const wronglink1 = wronglinks[norn(noopt)];
const wronglink2 = wronglinks[norn(noopt)];
const wronglink3 = wronglinks[norn(noopt)];
*/

/// Making sure 1 of the three cookies is the right cookie:
///Make some cookies

var cookies = [false, false, false];
var rn = require('random-number');
var rnopt = {
  min: 0,
  max: 2,
  integer: true
}

// A random cookie becomes true
cookies[rn(rnopt)] = true;

/*
var link1 = cookies[0] == true ? yeslinks[yesrn(yesopt)] : wronglinks[norn(noopt)];
var link2 = cookies[0] == true ? yeslink2 : wronglink2;
var link3 = cookies[0] == true ? yeslink3 : wronglink3;
*/


///Building Out the App
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Pick the right one.</h1>
        <div>  
        <a href = {cookies[0] == true ? yeslinks[yesrn(yesopt)] : wronglinks[norn(noopt)]} >        
          <img width="300" height="300" src="/cookie.png"/>
          </a>
        </div>
        <div>  
          <a href = {cookies[1] == true ? yeslinks[yesrn(yesopt)] : wronglinks[norn(noopt)]} >        
          <img width="300" height="300" src="/cookie.png"/>
          </a>
        </div><div>  
          <a href = {cookies[2] == true ? yeslinks[yesrn(yesopt)] : wronglinks[norn(noopt)]} >        
          <img width="300" height="300" src="/cookie.png"/>
          </a>
        </div>
      </header>


    </div>
  );
}

export default App;
